/**
 * 示例-自定义组件-省市区联动
 * luxinwen
 * 2023-02
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="page-main-content">
        <div class="page-main-content-thead">sp-region 省市区联动</div>
        <div class="page-main-content-subhead">示例</div>
        <Card class="page-main-content-item">
          <sp-region v-model="value" class="width-m"></sp-region>
          <Divider orientation="left" plain>基本用法</Divider>
          <pre>&lt;sp-region v-model="value"&gt;&lt;/sp-region&gt;</pre>
          <p>基本用法。可以使用 v-model 双向绑定数据。</p>
          <p><span class="text-red">value</span> 只接受数组类型，组件会自动根据数组的值来返回选中的数据。Array 子项值接受 String、Number 或 Object 类型，如下传参皆可：</p>
          <pre>['海南省', '三亚市', '海棠区']
[460000, 460200, 460202]
[{ code: 460000, name: '海南省' }, { code: 460200, name: '三亚市' }, { code: 460202, name: '海棠区' }]
[460000, '三亚市', { code: 460202, name: '海棠区' }]</pre>
        </Card>
        <Card class="page-main-content-item">
          <sp-region v-model="value" class="width-m" disabled></sp-region>
          <Divider orientation="left" plain>禁用状态</Divider>
          <pre>&lt;sp-region v-model="value" disabled&gt;&lt;/sp-region&gt;</pre>
          <p>设置属性 <span class="text-red">disabled</span>，可以禁用整个选择器。</p>
        </Card>
        <Card class="page-main-content-item">
          <sp-region v-model="value" class="width-m" format="object"></sp-region>
          <Divider orientation="left" plain>返回格式</Divider>
          <pre>&lt;sp-region v-model="value" format="object"&gt;&lt;/sp-region&gt;</pre>
          <p>设置属性 <span class="text-red">format</span>，可以选择返回数据格式，可选值有 <span class="text-red">object、code、name</span>，情况如下：</p>
          <p>当值为 <span class="text-red">name</span> 时，返回数据如：<span class="text-red">['海南省', '三亚市', '海棠区']</span></p>
          <p>当值为 <span class="text-red">code</span> 时，返回数据如：<span class="text-red">[460000, 460200, 460202]</span></p>
          <p>当值为 <span class="text-red">object</span> 时，返回数据如：<span class="text-red">[{ code: 460000, name: '海南省' }, { code: 460200, name: '三亚市' }, { code: 460202, name: '海棠区' }]</span></p>
        </Card>
        <div class="page-main-content-subhead">Props</div>
        <Table :columns="propsColumns" :data="propsData" border />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        params: {
          'value': {
            tips: '指定选中的省市区 value 值，可以使用 v-model 双向绑定数据。Array 子项值接受 String、Number 或 Object 类型',
            type: 'Array',
            default: '[]'
          },
          'format': {
            tips: '返回数据格式，可选值为 object / code / name',
            type: 'String',
            default: 'name'
          },
          'disabled': {
            tips: '是否禁用',
            type: 'Boolean',
            default: 'false'
          },
          'clearable': {
            tips: '是否可以清空选项',
            type: 'Boolean',
            default: 'true'
          },
          'placeholder': {
            tips: '选择框默认文字',
            type: 'String',
            default: '请选择'
          }
        },
        propsColumns: [
          {
            title: '属性',
            key: 'name',
            width: 140
          },
          {
            title: '说明',
            key: 'tips'
          },
          {
            title: '类型',
            key: 'type',
            width: 140
          },
          {
            title: '默认值',
            key: 'default',
            width: 160
          }
        ],
        value: [460000, '三亚市', { code: 460202, name: '海棠区' }]
      };
    },
    computed: {
      propsData() {
        let val = [];
        let data = this.params;
        Object.keys(data).forEach(key => {
          let item = Object.assign({}, data[key], {
            name: key
          });
          item.default = item.default || '-';
          val.push(item);
        });
        return val;
      }
    }
  };
</script>